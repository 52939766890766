import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import store from './store';
import 'babel-polyfill';
import 'url-search-params-polyfill';
import promise from 'es6-promise';
import 'classlist-polyfill';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import router from './router/router';
import Http from './until/http';
import '../public/eps-font-icon/style.css';
import '@/scss/iconfont.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
promise.polyfill();
Vue.prototype.$http=Http;
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Antd);
// iview
import iView from 'iview';
import 'iview/dist/styles/iview.css';
Vue.use(iView);
// scroll
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

// import slideshow from 'vueslideshow'
// Vue.use(slideshow)
// swiper
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css';
// Vue.use(VueAwesomeSwiper);
// 应用swiper
// import 'swiper/dist/css/swiper.css';

const el_scrollBar = (el) => {
    if (el._ps_ instanceof PerfectScrollbar) {
        el._ps_.update();
    } else {
        el._ps_ = new PerfectScrollbar(el, { suppressScrollX: false });
    }
};

Vue.directive('scrollBar', {
    inserted(el) {
        el_scrollBar(el);
    },
    componentUpdated(el, binding, vnode) {
        try {
            vnode.context.$nextTick(
                () => {
                    el_scrollBar(el);
                }
            )
        } catch (error) {
            el_scrollBar(el);
        }
    }
});

new Vue({
    render: h => h(App),
    router: router,
    store: store
}).$mount('#app')
