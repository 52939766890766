<template>
    <div id="app" style="height:100%">
        <router-view></router-view>
    </div>
</template>
<script>
import { urlSoz} from './until/common'

export default {
	name:'App',
	components:{
    },
    data() {
        return {
            urlData:'',
        }
    },
    computed:{

    },
    created(){
        if(document&&document.location.hostname===urlSoz){
            this.urlData = 'sozdata'
        }else{
            this.urlData = 'epsdata'
        }
    },
    methods: {
    },
    mounted() {
        if(window.location.origin===urlSoz) {
            document.title='北京搜知数据科技有限公司';
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = '/favicon2.ico';
            document.getElementsByTagName('head')[0].appendChild(link);
        } else {
            document.title='EPSDATA官网';
        }
    },
}
</script>
<style lang="scss">
    @import './scss/common.scss';
    @import './scss/index.scss';
    @import './scss/style.scss';
    @import './scss/procenter.scss';
    @import './scss/service.scss';
    @import './scss/searchweb.scss';
   .ant-modal-footer{
        text-align: center !important;
    }
    .ant-modal-title{
        color: #333;
    }
    .ant-modal-body{
        p{
            color: #333;
            line-height: 28px;
        }
    }
    .descWrap{
        color: #333;
        overflow: hidden;
        margin-top: 40px;
        .fl{
            float: left;
        }
        .fr{
            float: right;
            text-align: right;
        }
    }
</style>