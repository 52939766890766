import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
		madelText:'',
		tipFlag:false,
    },
    getters:{
		/* getMadels(state) {
			return state.madelText;
		}, */
		getNotices(state) {
			return state.tipFlag;
		},
    },
    mutations:{
		setMadels(state,data) {
			state.madelText = data;
		},
		setNotices(state,data) {
			state.tipFlag = data;
		},
    },
    actions:{
		setMadels(context,data) {
			context.commit('setMadels',data);
		},
		setNotices(context,data) {
			context.commit('setNotices',data);
		},
    }
});
 
export default store;