import axios from 'axios'
const olapUrl = 'http://edp.epsnet.com.cn/'; //（用于数据详情页的地址）
const urlEpsBak = 'https://olap.epsnet.com.cn/'; //（eps数据平台的地址）
const urlWgBak = 'http://microdata.sozdata.com/'; //（微观经济平台的地址）
const urlKnowBak = 'http://kdd.epsnet.com.cn/'; //（知识服务平台的地址）
const urlGmlqBak = 'http://crod.epsnet.com.cn/'; //（革命老区平台的地址）
const urlQyjjBak = 'http://cnrrd.sozdata.com/'; //（区域经济平台的地址）
const urlCjjjdBak = 'http://yreb.sozdata.com/'; //（长江经济带平台的地址）
const urkSozBak = 'https://zhitu.sozdata.com/';//(知图学术平台的地址)
const urlSoz = 'www.sozdata.com';
const urlDfinder = 'http://www.dfinder.net';
/*
 * 刚哥的接口地址，以后用的着（检验和查询数据用）
 * http://oldolap.epsnet.com.cn/sys/COMGETDATA.ashx?sp=News_GetList&p=PageIndex;newsitem&v=1;11
 */ 
const config = {
    baseBannerUrl : 'https://cms.sozdata.com',
    baseUrl: 'https://www.epsnet.com.cn/',//线上地址(正式线上)//http://121.52.216.138:8181/-//'http://121.52.216.251:8181/',
    urlMap: {
        // 新接口（栾鹏）
        getSearchExcel: 'api/search/excel', //（新）统计报表结果页接口
        getcomgedatas: 'api/custom/data', //（新）数据定制 （提交接口）
        cubeStatNums:'api/cubeStatNums',//EPS数据量
        getDownloadTop: 'getDownloadTop', // 首页//排行榜// 庆霖接口
        getEpsSearchPage:'searchPage.do',//时间序列数据搜索// 庆霖接口
        getSearchDimensionsMode: 'searchDimensionsMode.do', //搜索结果页选择指标和库//庆霖接口
        getTranSave: 'tran/save', //（jsonPost请求）//庆霖接口
        ontrial:'onTrail',//申请试用
        selectGroupByKeyword:'selectGroupByKeyword',
    }
}
/*首页的排行榜的动态数getDownloadTop接口用到了*/
const ajaxGet = function (name, params) {
    var url = config.baseUrl + config.urlMap[name] + '?' + Math.random();
    return axios.get(url, {
        params: params
    });
}
// 时间序列的结果页的页面getTranSave接口用到了
const jsonPost = function (name, param) {
    var url = config.baseUrl + config.urlMap[name];
    return axios.post(url, param);
}
// 统计报表搜索结果页的页面getSearExcelAjax接口用到了
const ajaxPost = function (name, param) {
    var url = config.baseUrl + config.urlMap[name];
    var params = new URLSearchParams();
    for (var i in param) {
        params.append(i, param[i]);
    }
    return axios.post(url, params);
}
const setLocalItem = function (key, value) {
    if (typeof (value) != 'string') {
        value = JSON.stringify(value);
    }
    return window.localStorage.setItem(key, value);
}
// html5本地储存封装
const setSessionItem = function (key, value) {
    if (typeof (value) != 'string') {
        value = JSON.stringify(value);
    }
    return window.sessionStorage.setItem(key, value);
}
// html5本地储存获取封装
const getSessionItem = function (key) {
    var string = window.sessionStorage.getItem(key);
    try {
        var obj = JSON.parse(string);
        if (typeof obj == 'object' && obj) {
            return obj;
        } else {
            if (string == 'null') {
                return null;
            } else {
                return string;
            }
        }
    } catch (e) {
        if (string == 'null') {
            return null;
        } else {
            return string;
        }
    }
}
const delSessionItem = function (name) {
    window.sessionStorage.removeItem(name);
}
const getLocalItem = function (key) {
    var string = window.localStorage.getItem(key);
    try {
        var obj = JSON.parse(string);
        if (typeof obj == 'object' && obj) {
            return obj;
        } else {
            if (string == 'null') {
                return null;
            } else {
                return string;
            }
        }
    } catch (e) {
        if (string == 'null') {
            return null;
        } else {
            return string;
        }
    }
}
const setCookie = function (name, value, time, domain, path) {
    var str = name + "=" + encodeURIComponent(value);
    if (time) {
        var date = new Date(time).toGMTString();
        str += ";expires=" + date;
    }
    str = domain ? str + ";domain=" + domain : str;
    str = path ? str + ';path=' + path : str;
    document.cookie = str;
}
const getCookie = function (name) {
    var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;

}
const delCookie = function (name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null)
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString() + "; path=/";
}
const checkEmail = function (name) {
    var pattern = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
    if (pattern.test(name)) {
        return true;
    } else {
        return false;
    }
}
//手机号验证
const checkMobile = function (name) {
    var pattern = /^1[0-9]{10}$/;
    if (pattern.test(name)) {
        return true;
    } else {
        return false;
    }
}
const isLowerIE = function () {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
    var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return 7;
        } else if (fIEVersion == 8) {
            return 8;
        } else if (fIEVersion == 9) {
            return 9;
        } else if (fIEVersion == 10) {
            return 10;
        } else {
            return 6; //IE版本<=7
        }
    } else if (isEdge) {
        return 'edge'; //edge
    } else if (isIE11) {
        return 11; //IE11  
    } else {
        return null; //不是ie浏览器
    }
}
const thousandSplit = function (num) {
    return ("" + num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
}
/* const changeMillion = function(num) {
    return Math.round(num/1000000);
}
 */
/* 
const downloadFile = function(url) {
    window.location.href=url+'&m_sid='+getCookie('m_sid');
} */
/* 
const getFile = function(url) {
    return axios.get(url);
} */
const urlGet= function(name) {
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r!=null) {
        return r[2];
    } else {
        return null;
    }
}
export {
    config,
    urlEpsBak,
    urlWgBak,
    olapUrl,
    // wwwUrl,
    ajaxGet,
    jsonPost,
    ajaxPost,
    setSessionItem,
    getSessionItem,
    delSessionItem,
    setLocalItem,
    getLocalItem,
    setCookie,
    getCookie,
    delCookie,
    isLowerIE,
    checkEmail,
    checkMobile,
    thousandSplit,
    urlKnowBak,
    urlGmlqBak,
    urlQyjjBak,
    urlCjjjdBak,
    urkSozBak,
    urlSoz,
    urlDfinder,
    urlGet
};