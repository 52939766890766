import axios from 'axios'
import service from './contactApi';
import config from './configs';

const get=(urlName,params)=>{
    let api = service[urlName];
    let baseUrl = config.baseUrl[api.site];
    return axios.get(baseUrl+api.url,{params}).then(res=>{
        return res.data;
    }).catch(e=>{
        return e;
    });
}

const post=(urlName,params,trans)=>{
    let api = service[urlName];
    let baseUrl = config.baseUrl[api.site];
    if(trans) {
        var postParams = new URLSearchParams();
        for(let i in params) {
            postParams.append(i,params[i]);
        }
        return axios.post(baseUrl+api.url,postParams,{}).then(res=>{
            return res.data;
        }).catch(e=>{
            return e;
        });
    } else {
        return axios.post(baseUrl+api.url,{params},{}).then(res=>{
            return res.data;
        }).catch(e=>{
            return e;
        });
    }
    
}

const Http = {
    post,
    get
}
export default Http