import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
export default new Router({
	mode:'hash',
	routes: [
        {
            path: '/Index',
            component:()=>import(/* webpackChunkName: "Index" */ '../components/Index.vue'),
			meta:{title:'首页'},
        },
        {
            path: '/Home',
            component:()=>import(/* webpackChunkName: "Index" */ '../components/Index.vue'),
			meta:{title:'首页'},
        },{
            path: '/Timeseries/:sName',
            component:()=>import(/* webpackChunkName: "Timeseries" */ '../components/Timeseries.vue'),
			meta:{title:'时间序列'},
        },{
            path: '/Reportform/:sName',
            component:()=>import(/* webpackChunkName: "Reportform" */ '../components/Reportform.vue'),
			meta:{title:'统计表格搜索数据结果'},
        },{
            path: '/Morelist/:side/:id',
            component:()=>import(/* webpackChunkName: "Morelist" */ '../components/Morelist.vue'),
			meta:{title:'统计表格搜索数据结果'},
        },{
            path: '/Paperdetails',
            component:()=>import(/* webpackChunkName: "Paperdetails" */ '../components/Paperdetails.vue'),
			meta:{title:'论文引用详情页'},
        },{
            path: '/About',
            component:()=>import(/* webpackChunkName: "About" */ '../components/About.vue'),
			meta:{title:'关于我们'},
        },{
            path: '/Product',
            component:()=>import(/* webpackChunkName: "Product" */ '../components/Product.vue'),
			meta:{title:'产品中心'},
        },{
            path: '/Service',
            component:()=>import(/* webpackChunkName: "Service" */ '../components/Service.vue'),
			meta:{title:'项目服务'},
        },{
            path: '/Customiz',
            component:()=>import(/* webpackChunkName: "Customiz" */ '../components/Customiz.vue'),
			meta:{title:'数据定制'},
        },{
            path:'/Agreements',
            component:()=>import(/* webpackChunkName: "Agreements" */ '../components/Agreements.vue'),
			meta:{title:'用户使用协议'},
        },{
            path:'/Apply',
            component:()=>import(/* webpackChunkName: "Apply" */ '../components/ApplyOntrial.vue'),
			meta:{title:'申请试用'},
        },{
            path:'/info',
            component:()=>import(/* webpackChunkName: "info" */ '../components/info.vue'),
			meta:{title:'基本信息'},
        },{
            path:'/Statements',
            component:()=>import(/* webpackChunkName: "Statements" */ '../components/Statements.vue'),
			meta:{title:'用户使用协议'},
        },{
            path:'/Login',
            component:()=>import(/* webpackChunkName: "Login" */ '../components/Login.vue'),
			meta:{title:'登录'},
        },{
            path:'/publicResource',
            component:()=>import(/* webpackChunkName: "publicResource" */ '../components/PublicResource.vue'),
			meta:{title:'公共数据资源'},
        },{
            path:'/thesis',
            component:()=>import(/* webpackChunkName: "thesis" */ '../components/Thesis.vue'),
			meta:{title:'论文引用'},
        },{
            path: '/',
            redirect: '/Index'
        }
	]
});