const CONTACT_API = {
    //新增赛事
    getSign:{
        site:1,
        url:'getSign',        
    },
    accountGetVerifyCode:{
        site:1,
        url:'verifyCode'
    },
    getIp:{
        site:1,
        url:'user/getIp'
    },
    epsLogin:{
        site:1,
        url:'user/login'
    },
    epsGetUserInfo:{
        site:1,
        url:'userInfo.do'
    },
    zhitu_getSign:{
        site:2,
        url:'main/verify/getSign'
    },
    zhitu_accountGetVerifyCode:{
        site:2,
        url:'main/verify/sendCode',
    },
    zhitu_login:{
        site:2,
        url:'main/register/registerUser'
    },
    wg_login:{
        site:3,
        url:'login.do'
    },
    wg_ip_login:{
        site:3,
        url:'iplogin.do'
    },
    wg_getSign:{
        site:3,
        url:'getSign',
    },
    wg_getVerifyCode:{
        site:3,
        url:'verifyCode.do',
    },
    wg_loginByMacAddr:{
        site:3,
        url:'loginByMacAddr'
    },
    kdd_login:{
        site:4,
        url:'login'
    },
    changjiang_login:{
        site:5,
        url:'cj/login/rmLogin'
    },
    laoqu_login:{
        site:6,
        url:'user/login'
    }

}
export default CONTACT_API;