const config = {
    baseUrl:{
        1:'https://olap.epsnet.com.cn/',
        2:'https://zhitu.sozdata.com/',
        3:'http://microdata.sozdata.com/',
        4:'http://kdd.epsnet.com.cn/',
        5:'http://yreb.sozdata.com/',
        6:'http://crod.epsnet.com.cn/'
    }
}
export default config;